import React, { useState, useCallback } from "react";
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeadingModule from "../components/modules/heading-module"

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

class RoomPageTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const images = post.frontmatter.additionalImages;

    const createPhotosObject = ( images ) => {
      var photos = [];
      if (images && images.length) {
        images.map(({ image }) => {
          photos.push({
            src: image.childImageSharp.fixed.src,
            width: image.childImageSharp.fixed.width,
            height: image.childImageSharp.fixed.height
          });
        });
      }
      return photos;
    }

    const photos = createPhotosObject(images);

    const GalleryComponent = () => {
      const [currentImage, setCurrentImage] = useState(0);
      const [viewerIsOpen, setViewerIsOpen] = useState(false);

      const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
      }, []);

      const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
      };

      return (
        <div>
          <Gallery photos={ photos } margin={ 10 } onClick={ openLightbox } />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map(x => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      );
    }

    return (
      <Layout location={ this.props.location } title={ siteTitle }>
        <SEO
          title={ post.frontmatter.title + ' - Niwot Inn & Spa' }
          description={ post.frontmatter.description || post.excerpt }
        />
        <div className="rooms-page">
          <HeadingModule
            headingText={ post.frontmatter.title }
            subHeadingText={ post.frontmatter.description }
            headingImageAbove={ post.frontmatter.mainImage.childImageSharp.fluid }
            headingImageAboveMaxHeight={ 400 }
            backLinkUrl="/rooms/"
            backLinkText="All Rooms"
          />
          <section className="darker py-5">
            <div className="container">
              <div className="row">
                <div className="col-100">
                  <article>
                    <section dangerouslySetInnerHTML={{ __html: post.html }} />
                  </article>
                </div>
              </div>
            </div>
          </section>
          <div className="container">
            <div className="row">
              <div className="col-100 py-5">
                <h2>Photo Gallery</h2>
                <GalleryComponent />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default RoomPageTemplate

export const pageQuery = graphql`
  query RoomPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        mainImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        additionalImages {
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
              fixed(width: 1000) {
                src
                aspectRatio
                width
                height
              }
            }
          }
        }
      }
    }
  }
`
